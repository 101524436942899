.container{
  /* width: 1920px; */
  width: 100%;
  position: relative;
  min-width: 1300px;
}
header{
  width:1178px;
  display: flex;
  height: 74px;
  background-color: white;
  box-shadow: 0px 2px 4px 0px rgba(239, 239, 239, 0.5);
  margin: 0 auto;
 
}
.button1:hover{
  cursor: pointer;
}
.button2:hover{
  cursor: pointer;
}
.context{
  background:url(./assets/bg-1.jpg);
  background-repeat: round;
  width: 100%;
  height: 568px;
  position:relative
  
}
.context1{
  width:100%;
  /* width: 1920px; */
  height: 438px;
  background-color:#f7f9ff ;
  position: relative;
}
.introduction{
  background:url(./assets/bg-2.jpg);
  background-repeat: round;
  width: 1174.27px;
  height: 233px;
  position: absolute;
  top: -26px;
  left: 50%;
  transform: translateX(-50%);
}
footer{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom:53px ;
  color:#a1a1a1;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  height: 22px;
}
.title{
  width: 104px;
  height: 36px;
  color:#0c1e51;
  font-size: 26px;
  font-family:HarmonyOS_Sans_SC_Medium;
  font-weight: 500;
  margin-top: 32px;
  margin-left: 58px;
  margin-bottom: 0px;
}
.button1{
  width: 137px;
  height: 52px;
  background-color:#e62035;
  border-radius: 4px 4px 4px 4px;
  color:#ffffff;
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  font-family: PingFangSC-Semibold;
  border: 0px;
}
.button1 div{
  display: inline-block; 
  margin-left: 9px; 
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #ffffff;
  border-right: 7px solid transparent;
}
img{
  width: 116px;
  height: 33.28px;
  margin-top:20px;
  /* margin-left:380px ; */
}
.button2{
  width: 66px;
  height: 34px;
  display: inline-block;
  background-color: #ffffff;
  border: 1px solid #152344;
  border-radius: 2px 2px 2px 2px ;
  font-size: 14px;
  font-family:PingFangSC-Semibold;
  font-weight: 600;
  line-height: 22px;
  margin-left: 996px;
  margin-top: 20px;
}
a{
  text-decoration: none;
  color:#a1a1a1;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  letter-spacing: 0px;
}
